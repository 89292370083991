import React, { useState } from 'react'
import { ListScreen } from '../components'
import { GridColumn } from '@progress/kendo-react-grid'
import { useTranslation } from 'react-i18next'

const Module = ({ id }) => {
    const { t } = useTranslation()
    const [usersend] = useState({ parentid: id })
    return (
        <div>
            <ListScreen
                read={'ml000001'}
                delete={'ml000004'}
                userSendData={usersend}
                navigationUrl={id}
            >
                <GridColumn field='id' title={t('id')}></GridColumn>
                <GridColumn field='name' title={t('name')}></GridColumn>
            </ListScreen>
        </div>
    )
}

export default Module