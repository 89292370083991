import React from 'react'
import { FibaCheckbox, FibaInput, FibaPhotoInput, Form } from '../components'
import { Field } from '@progress/kendo-react-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Module from './Module'

const ModuleDetail = () => {
  const { t } = useTranslation()
  const { id, parentid } = useParams()
  const navigate = useNavigate()

  const onFinish = () => {
    navigate(-1)
  }

  return (
    <div className='mx-auto max-w-7xl'>
      <Form
        initialValues={{ is_public: 0 }}
        detail={'ml000005'}
        create={'ml000002'}
        update={'ml000003'}
        id={id}
        userSendData={{parentid}}
        afterRedirect={parentid ? true : false}
        onFinish={parentid ? onFinish : false}
      >
        <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4">
          <Field
            name={'logo'}
            component={FibaPhotoInput}
            label={t('logo')}
            required={true}
          />
          <Field
            name={'name'}
            component={FibaInput}
            label={t('name')}
            required={true}
          />
          <Field
            name={'name2'}
            component={FibaInput}
            label={t('name2')}
            required={true}
          />
          <Field
            name={'description'}
            component={FibaInput}
            label={t('description')}
            required={true}
          />
          <Field
            name={'is_public'}
            component={FibaCheckbox}
            label={t('is_public')}
            required={true}
          />
        </div>
      </Form>
      <div className='my-3'></div>
      {id !== '0' && <Module id={id} />}
    </div>
  )
}

export default ModuleDetail