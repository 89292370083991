import React, { useEffect, useState } from 'react'
import { FibaCheckbox, FibaDropdown, FibaInput, FibaPhotoInput, FibaTextArea, Form, GetCookie, RichTextEditor, SERVER_HOST, send, useFibaMsgBox, useLoading } from '../components'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Field } from '@progress/kendo-react-form'
import axios from 'axios'
import { TOKEN_NAME } from '../constants/token.const'

const types = [
  {
    value: '01',
    name: 'Энгийн /Текст/',
  },
  {
    value: '02',
    name: 'Линк холбох',
  },
  {
    value: '03',
    name: 'Файл оруулах',
  },
]

const InformationDetail = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { showLoading } = useLoading()
  const [menus, setMenus] = useState([])
  const [child_menus, setChildMenus] = useState([])
  const { error } = useFibaMsgBox()
  const [selectedFile, setSelectedFile] = useState()
  const { success } = useFibaMsgBox()
  const [render, setRender] = useState();
  const [showstate, setShowstate] = useState(false);
  const navigate = useNavigate()

  const getMenu = async () => {
    const res = await send('ml000001', {
      page: 1,
      perPage: 500
    }, showLoading);

    if (res.status === 'error') {
      error(res.message)
    } else {
      setMenus(res.data)
    }
  }

  const getChildMenu = async (id) => {
    const res = await send('ml000001', {
      page: 1,
      perPage: 500,
      parentid: id
    }, showLoading);

    if (res.status === 'error') {
      error(res.message)
    } else {
      setChildMenus(res.data)
    }
  }

  const onChangePicture = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
    } else {
      setSelectedFile(e.target.files[0])
    }
  }

  const onSubmit = async (data) => {
    let process_code = 'ml000012';
    if (data.id) {
      process_code = 'ml000013';
    }
    const formData = new FormData();
    for (const property in data) {
      formData.append(property, data[property]);
    }
    formData.append('files', selectedFile)
    // formData.append('custid', custid)

    showLoading(true)
    axios.post(SERVER_HOST + "/api/v1/back/process", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + GetCookie(TOKEN_NAME),
        'pc': process_code,
      },
      transformRequest: formData => formData,
    }).then((res) => {
      if (res.data.response_code === "RC000000") {
        success(t('success'));
        navigate('/information')
      } else {
        error(res.data.response);
        console.error(res.data);
      }
    }).catch((err) => {
      error('Алдаа гарлаа.');
      console.log(err);
    }).finally(() => {
      showLoading(false);
    })
  }

  useEffect(() => {
    if (!showstate) {
      setShowstate(true)
    }
  }, [showstate])

  useEffect(() => {
    getMenu()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='mx-auto max-w-7xl'>
      <Form
        initialValues={{ is_public: 0, type: '01', is_print: 0 }}
        detail={'ml000015'}
        create={'ml000012'}
        update={'ml000013'}
        id={id}
        onSubmit={onSubmit}
        setFormEvent={setRender}
        setDetailData={(data) => {
          getChildMenu(data.module_id)
        }}
      >
        {render && <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4">
          <Field
            name={'module_id'}
            component={FibaDropdown}
            label={t('menu')}
            data={menus}
            required={true}
            valueField={'id'}
            onChange={(event) => {
              getChildMenu(event.target.value)
            }}
          />
          <Field
            name={'module_parentid'}
            component={FibaDropdown}
            label={t('childmenu')}
            data={child_menus}
            valueField={'id'}
          />
          <Field
            name={'photo1'}
            component={FibaPhotoInput}
            label={t('photo1')}
          />
          <Field
            name={'name'}
            component={FibaInput}
            label={t('name')}
            required={true}
          />
          <Field
            name={'name2'}
            component={FibaInput}
            label={t('name2')}
            required={true}
          />
          <div className='col-span-full'>
            <Field
              name={'description'}
              component={FibaTextArea}
              label={t('description')}
              required={true}
            />
          </div>
          <Field
            name={'is_public'}
            component={FibaCheckbox}
            label={t('is_public')}
            required={true}
          />
          <Field
            name={'is_print'}
            component={FibaCheckbox}
            label={t('is_print')}
            required={true}
          />
          <Field
            name={'photo2'}
            component={FibaPhotoInput}
            label={t('photo2', { id: 1 })}
          />
          <Field
            name={'photo3'}
            component={FibaPhotoInput}
            label={t('photo2', { id: 2 })}
          />
          <Field
            name={'type'}
            component={FibaDropdown}
            label={t('type')}
            required={true}
            data={types}
            onChange={() => { setShowstate(false) }}
          />
          {(showstate && render.valueGetter('type') === '01') && <div className="col-span-full">
            <Field
              name={'information'}
              component={RichTextEditor}
              label={t('information')}
              required={true}
            />
          </div>}
          {(showstate && render.valueGetter('type') === '03') && <Field
            name={'file1'}
            type="file"
            component={FibaInput}
            label={t('input_file')}
            onChange={onChangePicture}
            required={id ? false : true}
          />}
          {(showstate && render.valueGetter('type') === '02') && <Field
            name={'information'}
            component={FibaTextArea}
            label={t('information')}
            required={true}
          />}
        </div>}
      </Form>
    </div>
  )
}

export default InformationDetail