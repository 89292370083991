import React, { Suspense } from 'react'
import { BrowserRouter, Routes, Route, Navigate, useParams } from 'react-router-dom'
import Login from '../Auth/Login'
import NotFound from '../layouts/error/NotFound'
import PrivateRoute from './PrivateRoute'
import ChangePassword from '../Auth/ChangePassword'
import Profile from '../Auth/Profile'
import Module from '../pages/Module'
import Information from '../pages/Information'
import InformationDetail from '../pages/InformationDetail'
import ModuleDetail from '../pages/ModuleDetail'
import OrgLogo from '../pages/OrgLogo'
import OrgLogoDetail from '../pages/OrgLogoDetail'

const loadingComp = <div className="k-loading-mask" style={{ zIndex: 100000 }}>
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
</div>

const RedirectComponent = () => {
    const { parentid } = useParams();
    return <Navigate to={`/module/${parentid}`} />;
};

export default function CoreRoutes() {
    return (
        <BrowserRouter>
            <Suspense fallback={loadingComp}>
                <Routes>
                    <Route exact path='/' element={<PrivateRoute />}>
                        <Route exact path='/module' element={<Module />} />
                        <Route exact path='/module/:id' element={<ModuleDetail />} />
                        <Route exact path='/module/:parentid/:parentid' element={<RedirectComponent />} />
                        <Route exact path='/module/:parentid/:parentid/:id' element={<ModuleDetail />} />
                        <Route path="/changePassword" exact element={<ChangePassword />} />
                        <Route path="/profile" exact element={<Profile />} />
                        <Route path="/information" exact element={<Information />} />
                        <Route path="/information/:id" exact element={<InformationDetail />} />
                        <Route path="/orglogo" exact element={<OrgLogo />} />
                        <Route path="/orglogo/:id" exact element={<OrgLogoDetail />} />
                    </Route>
                    {/* <PrivateRoute exact path="/login-history" element={LoginHistory} /> */}
                    <Route path="/login" element={<Login />} />
                    {/* <Route path="/forgotpassword" exact element={<ForgotPassword />} />
                    <Route path="/resetPassword/:token" exact element={<ResetPassword />} /> */}
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}
